







































































































































.card-map{
  min-height: 350px;
  .map{
    height: 300px;
    width: 100%;
  }
}
